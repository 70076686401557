// Helper
import API from "@/helpers/fetch";


export const calendarMixins = {
    computed : {
        /* Globa state ward calendar */
        wardcalendar: {
            get() {
                return this.$store.state.globalWardCalendar
            },
            set(list) {
                this.$store.commit('updateWardCalendar', list)
            }
        },
        /* Global state stake calendar */
        stakeCalendar: {
            get() {
                return this.$store.state.globalStakeCalendar
            },
            set(list) {
                this.$store.commit('updateStakeCalendar', list)
            }
        },
    },
    methods: {
         /* Get stake calendar */
         async getStakeCalendar() {
            if (!process.env.VUE_APP_STAKEAPIBASEURL) return;

            const res = await API.fetch({
                baseUrl: process.env.VUE_APP_STAKEAPIBASEURL,
                url: "churchapp/v1/page/stake-calendar",
                method: "GET",
            });

            if (res) {
              
                this.stakeCalendar = res;
            }
        },
        /* Get ward calendar */
        async getwardcalendar() {
            const res = await API.fetch({
                url: "churchapp/v1/page/ward-calendar",
                method: "GET",
            });

            if (res) {
                this.wardcalendar = res;
            }
        },
    }
}